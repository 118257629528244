export const PREFIX_KEY = "lifely_";

// Local Storage KEY
export const LS_TOKEN_KEY = PREFIX_KEY + "token_user";
export const LS_REFRESH_TOKEN_KEY = PREFIX_KEY + "refresh_token_user";
export const LS_USER_KEY = PREFIX_KEY + "user_json";

export const BASE_URL_API = process.env.VUE_APP_BASE_URL_API;
export const URL_API_USER_LOGIN = BASE_URL_API + "social_auth/token";
export const URL_API_DEVICE_LIST = BASE_URL_API + "manager/devices/";
export const URL_API_PUBLIC_PLANT = BASE_URL_API + "objects/public-plant";
export const URL_API_CUSTOMER_LIST = BASE_URL_API + "manager/customers";
export const URL_API_SUMMARY = BASE_URL_API + "dashboard/summary/";
export const URL_API_UNVALIDATED_PHOTO_LIST = "dashboard/image/not-validated-list/";

export const CLIENT_ID = "NrcXgalPWgvyrtqKymxU2PHmz3xOtqPmxs6vMt95";
export const CLIENT_SECRET = "fh5r4vZkoMQAeRfyQPgae9MQxfqN01dwp6N31onSXZ7ZAeEfTba6cV2XQMYIAnVNlXu6CV2b9LSwD5S2Vx7UHoWadK0knAiPwfFKEOdDr7ENzOOE2Vx73F9KRE8Cg8z2";

export const GRANT_TYPE_PASSWORD = "password"
export const GRANT_TYPE_REFRESH = "refresh_token"