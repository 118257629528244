import Vue from "vue";
import VueRouter from "vue-router";
import {StorageHelper} from "../common/storageHelper";
import {refreshToken} from "@/api";

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/Login.vue")
    },
    {
        path: "/devices",
        name: "devices",
        alias: "/",
        meta: { requiresAuth: true },
        component: () => import("../views/Devices.vue")
    },
    {
        path: "/devices/:device_id",
        name: "device_detail",
        meta: { requiresAuth: true },
        component: () => import("../views/DeviceDetail.vue")
    },
    {
        path: "/customers",
        name: "customers",
        meta: { requiresAuth: true },
        component: () => import("../views/Customers.vue")
    },
    {
        path: "/customers/:customer_id",
        name: "customer_detail",
        meta: { requiresAuth: true },
        component: () => import("../views/CustomerDetail.vue")
    },
    {
        path: "/customers/:customer_id/plants/:plant_id",
        name: "plant_detail",
        meta: { requiresAuth: true },
        component: () => import("../views/PlantDetail.vue")
    },
];

const routerManager = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    linkActiveClass: "active", // active class for non-exact links.
    linkExactActiveClass: "active", // active class for *exact* links.
    routes
});

routerManager.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        if (StorageHelper.getToken()) {
            console.log(StorageHelper.getRefreshToken())
            refreshToken(StorageHelper.getRefreshToken()).then( () => {
                if (to.path === "/") {
                    next({name : "devices"})
                } else {
                    next();
                }
            })

        } else {
            next({ name: "login" });
        }
    } else {
        if (to.name === "login" && StorageHelper.getToken()) {
            next({name : "devices"})
        } else {
            next();
        }
    }
});

export default routerManager;
