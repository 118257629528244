import Vue from "vue";
import App from "./App.vue";
import routerManager from "@/router/routerManager";
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';


Vue.config.productionTip = false;
Vue.use(BootstrapVue)

const router = () => {
  return routerManager;
};


new Vue({
  router: router(),
  render: h => h(App)
}).$mount("#app");
