import axios from "axios";
import {
    BASE_URL_API, CLIENT_ID, CLIENT_SECRET, GRANT_TYPE_PASSWORD, GRANT_TYPE_REFRESH,
    LS_TOKEN_KEY, URL_API_CUSTOMER_LIST, URL_API_DEVICE_LIST, URL_API_PUBLIC_PLANT,
    URL_API_USER_LOGIN

} from "../common/constants";
import {StorageHelper} from "@/common/storageHelper";

export const client = axios.create({
  baseURL: BASE_URL_API,
  responseType: "json"
});

export function generateAuthorizationHeader() {
    console.log("TOKEN -> " + localStorage.getItem(LS_TOKEN_KEY))
  return {
    headers: {
      Authorization: "Bearer " + localStorage.getItem(LS_TOKEN_KEY)
    }
  };
}

export const refreshToken = (refresh_token) => {

    const params = new URLSearchParams();
    params.append('refresh_token', refresh_token);
    params.append('client_id', CLIENT_ID);
    params.append('client_secret', CLIENT_SECRET);
    params.append('grant_type', GRANT_TYPE_REFRESH);

    return client
        .post(URL_API_USER_LOGIN, params)
        .then(res => {
            StorageHelper.setToken(res.data.access_token)
            StorageHelper.setRefreshToken(res.data.refresh_token)
            return res
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const doLoginUser = (username, password) => {

    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    params.append('client_id', CLIENT_ID);
    params.append('client_secret', CLIENT_SECRET);
    params.append('grant_type', GRANT_TYPE_PASSWORD);

  return client
    .post(URL_API_USER_LOGIN, params)
    .then(res => {

        if(res.data.is_staff === false){
            throw new Error("Unathorized")
        } else {
            StorageHelper.setToken(res.data.access_token)
            StorageHelper.setRefreshToken(res.data.refresh_token)
            return res.data;
        }
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const getDevices = (page, query = "") => {
    let url = URL_API_DEVICE_LIST + "?page=" + page;
    url += "&search=" + query

    return client
        .get(url, generateAuthorizationHeader())
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const getDeviceDetail = (id) => {
    return client
        .get(URL_API_DEVICE_LIST + id + "/", generateAuthorizationHeader())
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const getDeviceDetailRecords = (id, page) => {
    return client
        .get(URL_API_DEVICE_LIST + id + "/records/?page=" + page, generateAuthorizationHeader())
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const getDeviceDetailLastRead = (id) => {
    return client
        .get(URL_API_DEVICE_LIST + id + "/last_read", generateAuthorizationHeader())
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const getCustomersList = (page, query = "") => {
    return client
        .get(URL_API_CUSTOMER_LIST + "?page=" + page + "&search=" + query, generateAuthorizationHeader())
        .then(res => {

            console.log("CUSTOMERS");
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const getCustomerDetail = (id) => {
    return client
        .get(URL_API_CUSTOMER_LIST + "/"+ id + "/", generateAuthorizationHeader())
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const getCustomerPlants = (id) => {
    return client
        .get(URL_API_CUSTOMER_LIST + "/" + id + "/plants/", generateAuthorizationHeader())
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const getPlantDetail = (id) => {
    return client
        .get(URL_API_PUBLIC_PLANT + "/" + id, generateAuthorizationHeader())
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

